.cart-info {
	margin:0 15px;
	a {
		color: $tertiary;
		transition: $transition-quick;
		i {
			font-size: 24px;
			float: left;
			margin-right: 5px;
			color: $primary;
		}
		&:hover {
			text-decoration: none;
		}
}
.cart-products-count {
	display: inline-block;
	background: $primary;
	border-radius: 50%;
	width: 23px;
	height: 23px;
	color: #FFF;
	line-height: 23px;
	text-align: center;
	font-size: 0.8rem;	
}
}


/*MODAL*/
 .fade.in{
    opacity:1
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    outline: 0;
}

.modal, .modal-open {
    overflow: hidden;
}

.fade {
    opacity: 0;
    transition: opacity .15s linear;
}

.modal-content{
  border-radius: 0;
  border: none;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 1;
  transition: all 0.3s;

}

.modal-backdrop {
     z-index: 2; 
	background: $primary-transparent;
} 