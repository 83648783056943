#block-reassurance {
	padding: 10px 15px;
	.element {
		text-transform: uppercase;
		text-align: center;
		font-size: 1.2rem;
		span {
			display: inline-block;
			height: 56px;
			line-height:56px;
			vertical-align: middle;
		}
		i:before {
			font-size: 36px;
			vertical-align: middle;
			margin-right: 15px;
		}
		&:nth-of-type(1) {
			i {
				&:before {
					content: "lock";
				}	
			}
		}
		&:nth-of-type(2) {
			i {
				&:before {
					content: "local_shipping";
				}	
			}
		}
		&:nth-of-type(3) {
			i {
				&:before {
					content: "replay";
				}	
			}
		}
	}
}
