.user-info {
	a {
		    color: $tertiary;
		    transition: $transition-quick;
			i {
				font-size: 24px;
				float: left;
				margin-right: 5px;
				color: $primary;
			}
			&:hover {
				text-decoration: none;
			}
	}
	.logged-in {
		display:flex;
		.info {
			line-height: calc(#{$header-height / 2 } - 1px);
			margin: 0;
			text-align: center;
			padding: 0 1.5rem;
			p {
				margin: 0;
				&.name {
					font-weight: bold;
					font-size: 0.9rem;
				}
			}
			.link {
				text-decoration: underline;
			}
		}
		.logout, .account {
			line-height: calc(#{$header-height} - 1px);
			padding: 0 0.8rem;
		}
	}
}