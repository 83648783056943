$primary: #b22930;
$secondary: #124866;
$tertiary: #177bb3; /*Cinza logo*/
$black: #000;
$white: #fff;
$link-color: $secondary;
$link-hover-color: $link-color;
$primary-transparent: rgba(178, 41, 48, 0.5);
$primary-medium: lighten($primary, 20%);
$secondary-darker: lighten($secondary, 30%);
$secondary-medium: lighten($secondary, 55%);
$secondary-light: lighten($secondary, 68%);
$tertiary-darker: lighten($tertiary, 50%);
$tertiary-medium: lighten($tertiary, 60%);
$tertiary-light: lighten($tertiary, 80%);
$black-medium: lighten($black, 60%);
$black-light: lighten($black, 80%);
$box-shadow: 0 .5rem 0.8rem rgba(#000, .30) ;
$box-shadow-medium: 0 .5rem 0.8rem rgba(#000, .20) ;
$box-shadow-small: 0 0 0.5rem rgba(#000, .15) ;
$transition-base: all 0.5s ease ;
$transition-quick: all 0.2s ease ;
$border-primary: solid 1px $primary;
$border-tertiary-medium: solid 1px $tertiary-medium;
$border-tertiary-light: solid 1px $tertiary-light;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
/*Input*/
$input-border: solid 1px $tertiary-medium;
$input-bg: #FFF;
$input-focus-box-shadow: 0px 0px 8px -3px rgba($black ,0.5);
$btn-focus-box-shadow: 0px 0px 8px -3px rgba($black ,0.5);
$header-height: 150px;
$slider-container: 350px;
$nav-height: 38px;
$font-family:  'Barlow', sans-serif;
$headings-font-family: 'Righteous', cursive;
$jumbotron-padding: 0;
$jumbotron-bg: $secondary;
$tooltip-bg: $secondary;
// Bootstrap and its default variables
@import "bootstrap/scss/bootstrap";
// Custom Components
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/brands";
@import "components/mainmenu";
@import "components/leftcolmenu";
@import "components/featuredproducts";
@import "components/glider";
@import "components/bxslider";
@import "components/lightbox";
@import "components/homecategories";
@import "components/banner";
@import "components/search-widget";
@import "components/imageslider";
@import "components/languageselector";
@import "components/product-list";
@import "components/category";
@import "components/product";
@import "components/breadcrums";
@import "components/gallerique";
@import "components/customer";
@import "components/shoppingcart";
@import "components/contactinfo";
@import "components/block-reassurance";
@import "components/footer";
@import "components/cart";
@import "components/videobanner";
@import "components/customtext";
@import "aos/aos";
@import url('https://fonts.googleapis.com/css?family=Barlow:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

  // tooltips
  .tooltip {
    background: $secondary;
  }
  
  
  // Custom Styles
  body {
    font-family: $font-family;
    color: $black;
    &:not(#index) {
      background: url(./img/banner-cover.png) no-repeat center center fixed; 
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .container {
    overflow: hidden;
    }
  
  a {
     outline: 0;
  }
  button {
    background-color: transparent;
  }

  .btn {
    &:focus {
      box-shadow: 0px 0px 8px -3px rgba($black ,0.5) !important;
    }
  }

  #return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: $tertiary-light;
    width: 50px;
    height: 50px;
    text-decoration: none;
    display: none;
    transition: $transition-base;
    box-shadow: $box-shadow-small;
    text-align: center;
    z-index: 999;
    border-radius: 50%;
    font-size: 0.9rem;
      i {
      color:$tertiary;
      padding: 13px;
      font-size: 24px;
      transition: $transition-base;
    }
    &:hover {
      background: $tertiary-darker;
    }
    &:hover i {
      color: #fff;
      transform: translateY(-3px);
    }
  }
  
  nav.top-nav {
    background: $primary;
    color: $white;
  }
  #header {
    box-shadow: none;
    transition: $transition-base; 
    background-color: $white;
  .navbar {
    transition: $transition-base;
    .navbar-brand {
      margin-left: 15px;
      transition: $transition-base;
      padding: 15px 0;
      flex-grow: 2;
      img {
        width: 100%;
        transition: $transition-base;
        margin-top: 0;
        max-width: 250px;
      }
    }
  }
  
  
  @media (max-width: 575px) {
    .navbar-scroll {
      padding: 5px 0;
    }
  }
  &.navbar-scroll {
      transition: $transition-base;
  
  }
  }
  
  .content {
    margin-top: 60px;
    background: rgba($white ,0.95);
    @include media-breakpoint-up(md) {
    padding: 15px 30px 15px 15px;
    }
  }
  
  .content-container {
    @include media-breakpoint-up(lg) {
    padding: 0;
    }
  }
  
  
  
  // forms & inputs
  
  input::-moz-focus-inner { 
    border: 0; 
  }
  
  input {
      border: $input-border;
      background: $input-bg;
      border-radius: $border-radius;
      padding:0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;	
  }
  .form-control {
      border: $input-border;
      padding:0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
      transition: $transition-quick;
      &:focus {
        transition: $transition-quick;
      }
  }
  .form-control-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #FFF url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
    padding: 0 0.5rem;
  }
  
  .product-quantity {
    .qty, .add {
      display: inline-block;
    }
    .qty {
      margin-bottom: 15px;
    }
    .add, .add .btn {
      width: 100%;
    }
    #quantity_wanted {
      color: $secondary;
      background-color: white;
      height: 2.79rem;
      padding: 0.175rem 0.5rem;
      width: 3rem;
    border:none;
    border-right: $input-border;
    }
    .input-group-btn-vertical {
      width: auto;
      .btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      padding: 0 4px;
      margin-left: -1px;
      position: relative;
        i {
          font-size: 1rem;
          top: 0.125rem;
          left: 0.1875rem;
        }
      }
    }
    .btn-touchspin {
      height: 1.438rem;
    }
  }
  
  .bootstrap-touchspin {
    width: auto;
    float: left;
    /*display: inline-block;*/
    border: $input-border;
    overflow: hidden;
    input {
      &:focus {
        outline: none;
      }
      &.form-control,
      &.input-group {
        color: $secondary;
        background-color: white;
        height: $input-height;
        padding: 0.175rem 0.5rem;
        width: 3rem;
        float: left;
      }
    }
    .btn-touchspin {
      background-color: white;
      border: solid 1px $secondary;
      height: $input-height / 2 + 0.0625rem;
      &:hover {
        background-color: $secondary-light;
      }
    }
    .input-group-btn-vertical {
      color: $secondary;
      width: auto;
      float: left;
      .bootstrap-touchspin-up {
        border-radius: 0;
      margin-bottom: -1px;
      border: none;
      border-bottom: solid 1px $secondary-light;
      }
      .bootstrap-touchspin-down {
        border-radius: 0;
      border:none;
      }
      .touchspin-up {
        &:after {
          content: "\E5CE";
  
        }
      }
      .touchspin-down {
        &:after {
          content: "\E5CF";
        }
      }
      i {
        top: 0.0625rem;
        left: 0.1875rem;
        font-size: 0.9375rem;
      }
    }
  }

  #cms {
    .page-header {
      font-size: 2rem;
      margin-bottom: 20px;
      font-family: $headings-font-family;
      color: $secondary;
    }
    .page-cms {
      img {
        max-width: 100%;
      }
    }
  }
  