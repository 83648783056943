.top-menu-container {
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: auto;
    }
    .navbar-toggler {
        width: 100%;
        color: $white;
        text-transform: uppercase;
        padding: 10px;
        background: $black;
        box-shadow: none !important;
        i {
            transform: rotate(90deg);
            transition: $transition-quick;
            margin-left: 15px;
        }
        
        &[aria-expanded=false] {
            background: $primary;
            i {
                transform: rotate(0deg);
                transition: $transition-quick;
            }
        }
    }
    .navbar-collapse {
        &.in {
            display: block;
        }

    }
}

#top-menu {
    background: $primary;
    display: block;
    @include media-breakpoint-up(lg) {
        background: none;
        display: flex;
    }
    .nav-item {
        .nav-link {
            color: $white;
            text-transform: uppercase;
            text-align: center;
            @include media-breakpoint-up(lg) {
            color: $black;
            border-radius: 0;
            transition: $transition-quick;
            padding: 15px;
            font-size: 1.2rem;
        }
        }
    }
}