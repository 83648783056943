/*Left Col Menu*/
.block-categories {
	@include media-breakpoint-up(lg) { 
		margin-right: 20px;
	}
        &.in {
            display: block;
        }
	.title {
		color: $secondary;
		padding:20px 45px;
		margin: 0;
		text-transform: uppercase;
		font-size: 1.3rem;
		font-weight: 700;;
	}

	div[aria-expanded="true"] {
		display: block;
	}

	ul {
		list-style: none;
		padding: 0;
	}
	.collapse-icons {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		color: $black;
		font-size: 1.2rem;
		transition: $transition-quick;
		line-height: 2.7rem;
		@include media-breakpoint-up(md) { 
		line-height: 1.1rem;
		}
		.add {
			@include media-breakpoint-up(md) { 
			transform: scaleY(100%) rotate(0);
			}
			@include media-breakpoint-down(md) { 
			visibility: visible;
			}
			transition: $transition-quick;
		  }
		&[aria-expanded="true"] {
		  .add {
			@include media-breakpoint-up(md) { 
			transform: scaleY(0) rotate(90deg);
			}
			@include media-breakpoint-down(md) { 
				visibility: hidden;
			}
			transition: $transition-quick;
		  }
		  .remove {
			@include media-breakpoint-up(md) { 
			transform: scaleY(100%) rotate(180deg);
			}
			@include media-breakpoint-down(md) { 
				visibility: visible;
			}
			transition: $transition-quick;
		  }
		}
		.remove {
		  margin-left: -17px;
		  @include media-breakpoint-up(md) { 
		  transform: scaleY(0) rotate(90deg);
		  margin-left: -13px;
		  }
		  transition: $transition-quick;
		}
	  }

  .category-sub-menu {
    li{
	  position: relative;
	  text-transform: uppercase;
	  font-size: 1.2rem;

	  }
	  li[data-depth="0"] {
		  border-bottom: solid 1px $black-light;
		  @include media-breakpoint-up(md) { 
			  border: 0;
		  }
		  &:hover {
			.collapse-icons {
				color: $primary;
				transition: $transition-quick;
			}
			a {
				color: $primary;
				transition: $transition-quick;
			}
			li[data-depth="1"] {
				a {
					color: $black;
					transition: $transition-quick;
				}
				&:hover {
					a {
						color: $primary;
						text-decoration: none;
						transition: $transition-quick;
					}
				}
			}

		  }
		  div.submenu {
			  border-left: solid 2px $primary;
		  }
	  li[data-depth="1"] {
		  div.submenu {
			  border-left: solid 5px $secondary;
			  margin-left: -5px;
		  }
	  }
	  &.current {
			  a.top-category {
				  color: $primary;
				  font-weight: 600;
			  }
		  }
	  a {
	    color: $black;
        width: 100%;
        display: inline-block;
		padding: 10px 0;
		@include media-breakpoint-up(md) { 
		padding: 0.25rem 0;
		line-height: 1.1rem;
		}
		transition: $transition-quick;
		text-decoration: none;
		font-size: 1.1rem;
		line-height: 2rem;
		&:hover {
				text-decoration: none;
		  }
		&.current {
			font-weight: 600;
		}

    }
		  
		   ul.category-sub-menu {

			   a {
				   font-weight: 400;
			   }
		}
	  }
	 
    li:not([data-depth="0"]) {
		&.current > a {
			font-weight: 600!important;
			color: $primary;
		}
		a {
			padding: 10px 0.75rem;
			line-height: 2rem;
			@include media-breakpoint-up(md) { 
			padding: 0.25rem 0.75rem;
			line-height: 1.1rem;
			}
			text-transform: none;
			font-size: 1rem;
		}
		i {
		  line-height: 10px;
	  }
		
    }	  
  }

}
.categories-button {
	text-align: center;
	border: solid 1px $black;
	padding: 10px;
	margin-top: 15px;
	width: 100%;
	font-size: 1.10rem;
	text-transform: uppercase;
	color: $black;
	i {
		margin-left: 10px;
	}
		.add {
			visibility: visible;
			transition: $transition-quick;
		  }
		&[aria-expanded="true"] {
		  .add {
			visibility: hidden;
			transition: $transition-quick;
		  }
		  .remove {
			visibility: visible;
			transition: $transition-quick;
		  }
		}
		.remove {
		  margin-left: -15px;
		  visibility: hidden;
		  transition: $transition-quick;
		}
}