#custom-text {
    @include media-breakpoint-up(xl) {
      padding: 80px;
    }
    text-align: center;
    h1 {
      color: $primary;
      font-weight: 700;
      @include media-breakpoint-up(xl) {
      font-size: 4rem;
      }
      text-transform: uppercase;
      margin: 0;
    }
    h3 {
        font-size: 1.3rem;
        margin-bottom: 15px;

    }
    p {
      @include media-breakpoint-up(xl) {
        font-size: 1.3rem;
      }
    }
  }