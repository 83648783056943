#homecategories {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
    .row {
       align-items:center; 
    }
    .intro {
        margin: 30px -12px;
        background-color: $secondary;
        padding: 15px;
        text-align: center;
        @include media-breakpoint-up(lg) { 
            margin: 0;
            background: none;
            padding: 30px;
            text-align: left;
        }
        h2 {
            color: $white;
            @include media-breakpoint-up(lg) { 
            color: $primary;
            }
        }
        p {
            color: $white;
            @include media-breakpoint-up(lg) { 
            font-size: 1.3rem;
            color: $black;
            }
        }
        .btn {
            border-color: $white;
            color: $white;
            @include media-breakpoint-up(lg) { 
                border-color: $primary;
                color: $primary;
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .homecategories {
        padding: 0 20px;
    }
}

.block-category {
	.brands {
        margin: 80px 0;
		.item {
            img {
                filter: saturate(0);
				transition: $transition-quick;
                width: 100%;
                height: auto;
                padding: 15px;
                &:hover {
					filter: saturate(1);
					transition: $transition-quick;
				}
            }
        }
	}
}