.nav-contactinfo {
	list-style: none;
	padding: 0 15px 0 0;
	margin: 0;
	/*border-bottom: solid 1px $secondary;*/
		li {
			padding: 8px 5px;
			font-size: 0.9rem;
			float: left;
			a {
				color: $secondary;
			}
			&:first-child {
				border-left: none;
				a {
					color: $primary;
					font-weight: 600;
				}
			}
			i {
				margin-right: 5px;
				color: $primary;
			}
		}
}