#homeslider-container {
	margin-bottom: 0;
	overflow: hidden;
	.carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 0;
        margin:0;
        & [data-bs-target] {
            padding: 0;
            margin: 0;
            width: 100%;
            background-color: $primary;
            height: 10px;
            border: 0;
        }
	}
	.carousel-item {
		.carousel-caption {
			left: 0;
			right: 40%;
            top: 0;
			bottom: 0;
			text-align: left;
			padding: 100px 0 50px 100px;
            font-size: 1.2rem;
            h3 {
                font-size: 3rem;
            }
		}
        &:nth-child(even) {
            .carousel-caption {
                left: 40%;
			    right: 0;
                text-align: right;
                padding: 100px 100px 50px 0;
            }
        }
	}

}