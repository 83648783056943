#products, .featured-products {
	.column {
		margin-bottom: 30px;
	}
}

.product-miniature {
	position: relative;
	transition: $transition-base;
	overflow: hidden;
	height:100%;
	margin-bottom: 20px;
	padding: 15px;
	max-height: 800px; /*fix EDGE height*/
	a {
		text-decoration: none;
	}
	.image {
		border: solid 1px $black-light;
		transition: $transition-base;
		img {
			width: 100%;
			height: auto;
		}
	}
	.product-reference {
		color: $tertiary-darker;
		font-size: 0.8rem;
		padding-top: 5px;
	}
	.product-miniature-title {
		display: table;
		height: 88px;
		margin-bottom: 5px;
		width: 100%;

	}		
	.title {
		font-size: 1.5rem;
		transition: $transition-base;
		text-decoration: none;
		color: $black;
		margin: 0;
		padding: 8px 0;
		text-transform: none;
		vertical-align: middle !important;
		font-weight: 700;
	}
	p {
		color: $black;
	}
	&:hover {
		.image {
			border-color: $primary;
			transition: $transition-base;
		}
		.title {
		color: $primary;
		transition: $transition-base;
		}
	}

	.more-attributes {
		color: $tertiary-darker;
		margin-top: 5px;
		font-size: 0.8rem;
	}

	ul.product-flags {
		padding:0;
		margin: 0;
		position: absolute;
		left: 15px;
		top: 15px;
		display: block;
		li {
			padding: 5px;
			list-style: none;
			background: $primary;
			color: #FFF;
			text-align: center;
			text-transform: uppercase;
			font-size: 0.8rem;
		}
		
	}
	
	.product-prices {
		font-size: 1.6rem;
		.price {
			font-weight: 700;
		}
		.regular-price {
			text-decoration: line-through;
			text-decoration-color: $primary-medium;
			font-size: 1.2rem;
		}

	}
		.discount-product {
			padding: 5px;
			color: #FFF;
			text-align: center;
			text-transform: uppercase;
			border-radius: $border-radius;
		}

}
.pagination {
	padding: 15px;
	.page-list {
		text-align: right;
	}
}