#videobanner {
    width: 100%;
    @include media-breakpoint-up(xl) {
        overflow: hidden;
        height: 90vh;
        position: relative;
    }
    video {
        height: auto;
        width: 100%;
        @include media-breakpoint-up(xl) {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}