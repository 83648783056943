#category-description {
	margin: 20px 0;
}
.block-category {
	text-align: center;
	h1.title {
		color: $primary;
		margin-top: 0;
		text-transform: uppercase;
		font-size: 2.2rem;
		margin-top: 20px;
	}
	h1.title2 {
		color: $primary;
		margin-top: 0;
		text-transform: uppercase;
		font-size: 3rem;
		margin-bottom: 15px;
	}
	h3.title {
		margin-top: 0;
		font-size: 1.2rem;
		font-family: $font-family;
	}
}

.no-products {
	margin: 15px;
	padding: 15px;
	background: $tertiary-light;
}

#products {
	margin: 0 -15px;
}



.featured-title {
	padding: 15px;
	text-align: center;
	text-transform: uppercase;
}

.pagination {
width: 100%;
line-height: 15px;
  .page-list {
    background: none;
    margin-bottom: 0;
    li {
      display: inline;
    }
  }
  a {
    color: $primary;
	margin: 0 5px;
	line-height: 15px;
  }
  .previous {
	  text-decoration: none;
	  i {
	  line-height: 15px;
	  display: inline;
	  }
  }
  .next {
	  text-decoration: none;
	  i {
	  line-height: 15px;
	  display: inline;
	  }
  }
  .disabled {
    color: $secondary;
	cursor: default;
	text-decoration: none;
  }
  .current a {
    color: $primary;
    text-decoration: none;
	font-weight: 700;
  }
}

#subcategories {
	margin: 50px 0;
	.category-miniature {
		.item {
		background-size: cover;
		width: 200px;
		background-position: center center;
		height: 200px;
		margin: 0 auto;
		border-radius: 50%;
		overflow: hidden;
		border: solid 1px $secondary-light;
		transition: $transition-base;
		}
		a {
				color: $secondary-light;
				transition: $transition-base;
			}
		.title {
			text-align: center;
			text-transform: uppercase;
			font-weight:700;
			
			i {
				display: block;
				float: right;
				font-size: 0.8rem;
				margin-top: 0.2rem;
			}
			&.empty {
				height: 100%;
			}
		}
		&:hover {
			.item {
				border: solid 1px $primary;
				transition: $transition-base;
			}
			a {
				color: $primary;
				text-decoration: none;
				transition: $transition-base;
			}
		}
	}
}
 