.featured-products {
    @include media-breakpoint-up(lg) {
	margin: 140px 0;
    }
    .main-title {
        position: relative;
        overflow: hidden;
        margin-bottom: 50px;
        .bg {
            display: block;
            position: absolute;
            top: -5px;
            left: -5px;
            bottom: -5px;
            right: -5px;
            background-image: url(./img/produtosdestaque.png);
            background-size: cover;
            z-index: -9;
            filter: blur(5px);

        }
        h2 {
            text-align: center;
            color: #FFF;
            background-color: rgba($color: $secondary, $alpha: 0.5);
            margin: 0;
            padding: 15vh 0;
        }
    }
}