#footer {
	list-style: none;
	background: $primary;
	color: $white;
	padding:50px 0;
	width: 100%;
	text-align: center;
	@include media-breakpoint-up(lg) { 
		text-align: left;
	}
	.logo-brand {
		text-align: center;
		img {
			@include media-breakpoint-up(lg) { 
			width: 30%;
			margin: 0;
			}
			width: 10%;
			margin-bottom: 20px;
			height: auto;
		}
	}
	a {
		color: $white;
	}
	h4 {
		text-transform: uppercase;
		font-weight: 700;
	}
	.footer-contact {
		margin-bottom: 30px;
	}

	.btn-secondary {
		border:solid 1px $white;
		color: $white;
		background: #FFF;
		margin: 15px 0;
		font-size: 0.84rem;
	}
	.logos {
		text-align: center;
		.item {
			margin:15px 0;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		.center {
			border-left: solid 1px $white;
			border-right: solid 1px $white;
		}
	}
	.linklist {
		padding:0;
		text-align: center;
		margin: 20px auto;
		@include media-breakpoint-up(lg) { 
		margin: 0;
		text-align: right;
		}
		li {
			display: inline;
			list-style: none;
			border-right: solid 1px $white;
			padding: 0 15px;
			margin: 15px 0;
			font-size: 0.8rem;
			&:first-of-type {
				padding-left: 0;
			}
			&:last-of-type {
				border-right: none;
			}
			a {
				color: $white;
				text-decoration: none;
			}
		}
	}
	.disclaimer {
		font-size: 0.7rem;
		margin: 15px 0;
		p {
			margin: 0;
		}
	}
	
	input.email {
		width: 100%;
	}
}

.ps-social-follow {
	text-align: center;
	ul {
		list-style: none;
		padding: 0;
		li {
			display: inline;
			font-size: 2rem;
			a {
				text-decoration: none;
			}
		}
	}
}