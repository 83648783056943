.product_detail {
	margin-top: 20px;
	.right_col {
		padding-left: 20px;
	}
	ul.product-flags {
		padding:0;
		margin: 0;
		position: absolute;
		left: 15px;
		top: 0;
		display: block;
		z-index: 999;
		li {
			padding: 8px;
			list-style: none;
			background: $primary;
			color: #FFF;
			text-align: center;
			text-transform: uppercase;
			font-size: 0.9rem;
		}
		
	}

	.title {
		color: $secondary;
		text-transform: uppercase;
		margin: 0;
		font-size: 2.2rem;
		font-weight: 700;
	}
	.product-reference {
		font-size: 1.2rem;
		color: $tertiary;
		margin-bottom: 15px;
	}
	.product-manufacturer {
		img {
			padding: 0;
			border: 0;
			margin-bottom: 15px;
			width: 40%;
		}
	}

	.product-prices {
		margin-bottom: 20px;
		color: $primary;
		.regular-price {
			display: inline;
			text-decoration: line-through;
			color: $primary-medium;
			text-decoration-color: $primary-medium;
			font-size: 1.5rem;
		}
		.product-price {
			display: inline;
			.current-price {
				display: inline;
				font-size: 2.2rem;
				font-weight: 700;
			}
		}
	}

	.product-actions {
			margin-bottom:15px;
			.control-label {
				font-weight: 700;
				margin-bottom:10px;
				display: inline-block;
			}
			.add {
				.btn {
					height: 47px;
					font-weight: 700;
					i {
						margin-right: 10px;
					}
				}
			}
			.product-minimal-quantity {
				font-size: 0.9rem;
				margin-top: 10px;
			}
	}

	table {
		border: none;
		font-size: 1.3rem;
		th, td {
			border:none;
			h6 {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 1.8rem;
			}
		}
	}
}

#description {
	padding: 0;
	margin: 50px 0;
	h6.title {
		text-transform: uppercase;
		font-size: 2rem;
		color: $primary;
		margin-bottom: 20px;
		padding-bottom: 15px;
		border-bottom: solid 1px $black-light;

	}
}

.images-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	&.loaded {
	  padding-bottom: 0;
	}
	.loading-pimages {
	  opacity: 1;
	  height: 100%;
	  background-color: #FFF;
	  position: absolute;
	  left: 0;
	  right: 0;
	  top: 0;
	  bottom: 108px;
	  z-index: 999;
	  $transition-slow: all 0.8s ease;
	  img {
		width: 47px;
		height: 47px;
		margin: auto;
		display: block;
		margin-top: 46%;
	  }
	  &.loaded {
		opacity: 0;
		height: 0;
		$transition-slow: all 0.8s ease;
		z-index: -999;
	  }
	}
  
	.product-images {
	  .thumb-container {
		margin-bottom: 15px;
		cursor: pointer;
		img.thumb {
		  width: 100%;
		  height: auto;
		}
	  }
	}
  }
  .product-cover {
	position: relative;
	img {
	  background: white;
	}
	.layer {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  width: 100%;
	  height: 100%;
	  background: white;
	  position: absolute;
	  left: 0;
	  top: 0;
	  opacity: 0;
	  background: rgba(255, 255, 255, 0.5);
	  text-align: center;
	  cursor: pointer;
	  transition: $transition-base;
	  &:hover {
		opacity: 1;
		transition: $transition-quick;
	  }
	  .zoom-in {
		font-size: 3.25rem;
		color: $secondary;
	  }
	}
  }

.product-variants {
	h4 {
		text-transform: uppercase;
		color: $secondary;
		font-size: 1.2rem;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		&.color {
			display: flex;
		}
	}
  > .product-variants-item {
    margin:0 0 20px 0;
    select {
      background-color: #FFF;
      width: auto;
      padding-right: 1.875rem;
	  border: solid 1px $secondary;
	  &:hover {
		box-shadow: $box-shadow-medium;
	  }
	  &:focus {
		box-shadow: $box-shadow-medium;
	  }
    }
    .color {
      margin-left: 0;
      margin-top: 0;
		}
		.label-checked {
			font-weight: 700;
		}
  }
	.input-container {
		  position: relative;
		  margin-right: 15px;
		.input-color {
			  position: absolute;
			  opacity: 0;
			  cursor: pointer;
			  height: 30px;
			  width: 30px;
		}
		.color {
			width:30px;
			height: 30px;
			display: inline-block;
			border: solid 1px $secondary-light;
			cursor: pointer;
			background-size: contain;
		}
		.input-color:checked + .color {
			border: solid 2px $secondary-darker;
		}
	}

}

.form-control-select {
  height: 2.625rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #FFF url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
  padding: 0 0.5rem;
}


#product-modal {
	.modal-content {
	  padding: 0;
	  background: transparent;
	  border: none;
  
	  .modal-body {
		display: flex;
		margin-left: -30%;
  
		.product-cover-modal {
		  background: $white;
		}
  
		.image-caption {
		  width: 800px;
		  padding: 0.625rem 1.25rem;
		  background: $white;
		  p {
			margin-bottom: 0;
		  }
		}
  
		.thumbnails {
		  position: relative;
		}
  
		.mask {
		  position: relative;
		  z-index: 1;
		  max-height: 49.38rem;
		  margin-top: 2.188rem;
		  overflow: hidden;
  
		  &.nomargin {
			margin-top: 0;
		  }
		}
  
		.product-images {
  
		  img {
			width: 9.25rem;
			cursor: pointer;
			background: $white;
  
			&:hover {
			  border: $primary 3px solid;
			}
		  }
		}
  
		.arrows {
		  position: absolute;
		  top: 0;
		  z-index: 0;
		  width: 100%;
		  height: 100%;
		  color: $white;
		  text-align: center;
		  cursor: pointer;
  
		  .arrow-up {
			position: absolute;
			top: -2rem;
			opacity: 0.2;
		  }
  
		  .arrow-down {
			position: absolute;
			bottom: -2rem;
		  }
  
		  i {
			display: inline;
			font-size: 6.25rem;
		  }
		}
	  }
	}
  }

  .product-contact {
		  position: relative;
		  overflow: hidden;
		  color: $white;
		  margin-bottom: 50px;
		  form {
			background-color: rgba($color: $secondary, $alpha: 0.4);
			padding: 30px 40% 30px 30px;
		  }
		  .bg {
			display: block;
			position: absolute;
			top: -5px;
			left: -5px;
			bottom: -5px;
			right: -5px;
			background-image: url(./img/contacto.png);
			background-size: cover;
			background-position: right bottom;
			z-index: -9;
			filter: blur(5px);
			opacity: 0.5;
		  }
  }
  