.breadcrumb {
    width: 100%;
    background: none;
    padding: 0;
    margin:0;
    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: block;
      padding:0;
      float: left;
      &::after {
        content: "/";
        color: $black;
        margin: 0.3125rem;
		    font-size: 1rem;
      }
      &:last-child {
        content: "/";
        color: $tertiary;
        margin: 0;
        text-decoration: none;
        a {
          font-weight: 400;
        }
        &::after {
          content: "";
        }
      }
      a {
        color: $black;
        font-size: 1rem;
        font-weight: 700;;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }