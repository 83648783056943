.search-wrapper {
	width: 100%;
	height: 60px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -60px;
	z-index: 99;
	#searchWrapper {
		@include media-breakpoint-up(lg) {
			margin: 0;
			background-color: rgba($color: $secondary, $alpha: 0.7);
		}
		&.in {
			display: block;
		}
	}
	#search_widget {
		background-color: rgba($color: $secondary, $alpha: 0.7);
		@include media-breakpoint-up(lg) {
			background: none;
		}
		form {
			position: relative;
			padding: 10px 0;
			input {
				border: 0;
				height: 30px;
				padding: 19px;
			}
			.nav_search {
				width: 100%;
			}
			.submit {
				position: absolute;
				right: 0;
				top: 10px;
				border: 0;
				background-color: $white;
				color: $tertiary;
				line-height: 36px;
				margin: 0 15px;
				font-size: 1.2rem;
			}
		}

	}
}
a.searchbutton {
	transition: $transition-base;
	color: $white;
	background: $secondary;
	font-size: 1rem;
	text-decoration: none;
	width: 100%;
	text-transform: uppercase;
	text-align: center;
	padding: 5px;
	i {
		margin-left: 15px;
	}
	@include media-breakpoint-up(lg) {
		color: $tertiary;
		background: none;
		font-size: 1.2rem;
		width: auto;
	}
	.close {
		margin-left: -16px;
	}
	&[aria-expanded="false"] {
		.open {
			transform: scale(1);
			transition: $transition-quick;
		}
		.close {
			transform: scaleY(0);
			transition: $transition-quick;
		}
	}
	&[aria-expanded="true"] {
		.open {
			transform: scale(0);
			transition: $transition-quick;
		}
		.close {
			transform: scaleY(1);
			transition: $transition-quick;
		}
	}
	&:hover {
		@include media-breakpoint-up(lg) {
			color: $secondary;
		}
		transition: $transition-base;
	}
}


.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active, 
.ui-menu-item, .ui-menu-item:hover,
.ui-menu-item a, .ui-menu-item a:hover,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active,
.ui-menu .ui-menu-item a
{ 
background: #FFF none no-repeat; 
padding:0;
margin:0;
display:block;
border:0;border-collapse:collapse;
}

.ui-autocomplete {
	z-index: 9999;
	margin-left: -87px;
	border: none;
	border-radius: 0;
	padding: 0;
	box-shadow:$box-shadow-small;
	li {
		a {
			padding: 0.3rem 0.8rem !important;
			line-height: 27px;
			&:hover {
				background-color: $secondary-light !important;
				cursor: pointer;
			}

		}
		span {
			font-size: 0.9rem;
			padding-left: 0.8rem;
			
		}
	}
}