.home-gallery {
	#gallery_container {
		li{
			margin-top: 15px;
			a {
				background: $primary;
				color: $secondary;
				border: none;
				p {
					margin: 0.3rem 0.8rem;
					background: $primary;
					font-weight: 400;
					text-transform: uppercase;
					height: 27px; 
					line-height: 27px;
					display: inline-block;
					font-size: 18px;
				}
			}
		}
	}
}

#gallery_container {
	li {
		margin-bottom: 15px;
		background-image: none;
		a {
			padding: 0;
			border: solid 1px $primary;
			margin: 0;
			
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

#gallery_list {
	.gallerique-gallery {
			a {
				background: $primary;
				color: $secondary;
				border: none;
				display: block;
				p {
					margin: 5px 15px;
					font-weight: 400;
					text-transform: uppercase;
					height: 30px; 
					line-height: 30px;
				}
	}
	}
}
