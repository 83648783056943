.cart-grid {
  margin-bottom: 1rem;
}
.cart-items {
  margin-bottom: 0;
}
.cart-item {
  padding: 1rem 0;
}
.card-block {
  padding: 1.25rem 1.25rem .5rem;
  &.checkout {
    padding-bottom: 1.25rem;
    padding-top: 0;
  }
  &.cart-summary-totals {
    padding: 0 1rem 1rem;
    .cart-summary-line {
      padding: .5rem .2rem 0;
      
      &:last-child {
        margin-bottom: 0
      } 
      &.cart-total {
        background-color: $secondary-light;
        padding: .5rem;
        .label,
        .value {
          font-weight: bold;
        }
        .label {
          font-size: 1rem;
        }
        .value {
          font-size: 1.125rem;
        }
      }
      span {
        &.sub {
          text-align: left;
          font-size: .88rem;
          float: none;
        }
      }
    }
  }
}

.cart-summary-line {
  @include clearfix;
  clear: both;
  margin-bottom: .315rem;
  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
    color: $secondary;
  }
  .value {
    color: $secondary;
    float: right;
  }
  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}
/** CART BODY **/
.cart-grid-body {
  a.label {
    &:hover {
      color: $primary;
    }
  }
  .card-block {
    padding: 1rem;
    h1 {
      margin-bottom: 0;
    }
  }
  .cart-overview {
    padding: 1rem;
  }
  margin-bottom: 0.75rem;
}
/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    margin-bottom: 0;
    text-align: center;
    padding-bottom: .5rem;
    .cart-summary-line {
      .label {
        color: $secondary-medium;
        .code {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
  .cart-summary-line {
    &.cart-subtotal-products {
      .label,
      .value {
        font-weight: 600;
      }
      .value {
        font-size: 1rem;
      }
    }
  }
}
.block-promo {
  background: $secondary-light;
  .promo-code {
    padding: 1rem;
    .alert-danger {
      position: relative;
      margin-top: 1.25rem;
      background: $primary;
      color: white;
      display: none;
      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color:  $primary;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    form {
      text-align: center;
    }
  }
  .promo-input {
    color: $secondary;
    border: $secondary-medium 1px solid;
    height: 2.5rem;
    text-indent: 0.625rem;
    width: 50%;
    margin-right: -.24rem;
    + button {
      margin-top: -4px;
      text-transform: capitalize;
      border: 2px solid $primary;
    }
  }
  .cart-summary-line .label,
  .promo-name {
    color: $primary;
    font-weight: 600;
    margin-bottom: 0;
    a {
      font-weight: normal;
      color: $secondary;
      display: inline-block;
    }
  }
  .promo-name {
    .cart-summary-line {
      .label {
        font-weight: normal;
        color: $secondary;
      }
      span {
        color: $primary;
      }
    }
    .material-icons {
      font-size: 1.2rem;
      margin-left: .4rem;
    }
  }
  .promo-code-button {
    display: block;
    text-align: center;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;
    &[aria-expanded="true"] {
      display: none;
    }
    &.cancel-promo {
      color: $primary;
      margin-bottom: 0;
      &[aria-expanded="true"] {
        display: block;
        padding: 0;
        margin-top: 1rem;
      }
    }
  }
  &.promo-highlighted {
    padding: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
}
/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}
/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }
    > .out-of-stock {
      color: red;
    }
    > .available {
      color: $secondary;
    }
    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $secondary-medium;
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    color: $secondary;
    line-height: 36px;
    .remove-from-cart {
      color: $secondary;
      display: inline-block;
      margin-top: 0.3125rem;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }
  .cart-item {
    border-bottom: 1px solid $secondary-light;
    &:last-child {
      border-bottom: 0;
    }
  }
  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }
    .no-items {
      padding: 1rem;
      display: inline-block;
    }
  }
  .product-line-grid-left {
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }
    .price {
      width: 100%;
    }
  }
}



#blockcart-modal {
  color: $secondary;
  .modal-header {
    background: white;
    .close {
      opacity: 1;
      .material-icons {
        color: $secondary;
      }
    }
  }
  .modal-body {
    background: $secondary-light;
    padding: 3.125rem 1.875rem;
    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0 .5rem;
      } 
    }
  }
  .modal-dialog {
    max-width: 1140px;
    width: 100%;
	top: 10%;
  }
  .product-image {
    width: 100%;
    max-width: 9.375rem;
    display: block;
	background: #FFF;
	border:solid 1px $secondary-medium;
	padding: 15px;
  }
  .modal-title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 400;
    i.material-icons {
      margin-right: 20px;
      color: $primary;
    }
  }
  .product-name {
    color: $primary;
    font-size: 1.125rem;
    
    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    } 
  }
  .product-price {
    color: $secondary;
    display: block;
    @include media-breakpoint-down(sm) {
      padding: 0 .5rem;
    } 
  }
  
  .cart-content {
    padding-left: 20px;
    p {
      color: $secondary;
      display: flex;
      justify-content: space-between;
      padding: 0 .5rem;
      &.product-total {
        background-color: $secondary-light;
        padding: .5rem;
      }
      &.cart-products-count {
        font-size: 1rem;
        color: $primary;
        font-weight: 600;
      }
      &.product-tax {
        display: inherit;
        font-size: .875rem;
      }
      .label,
      .value {
        font-weight: 600;
      }
    }
    .cart-content-btn {
      display: inline-flex;
      button {
        margin-right: 0.9rem;
      }
      .btn {
        white-space: inherit;
      }
    }
  }
  .divide-right {
    border-right: 1px solid $secondary-medium;
  }
}
.product-images {
  > li.thumb-container {
    display: inline;
    > .thumb {
      @include box-shadow;
      cursor: pointer;
      margin-bottom: 20px;
      .images-container & {
        margin-right: 0.8125rem;
      }
      &.selected,
      &:hover {
        border: $primary 3px solid;
      }
    }
  }
}

#blockcart-modal .cart-content {
  .btn {
    margin-bottom: 20px;
  }
}
@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;
    img.product-cover-modal {
      width: 100%;
    }
    .arrows {
      display: none;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }
    .modal-body {
      padding: 1.875rem;
    }
  }
}